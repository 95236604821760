import '@assets/sharedStyles/modal.scss';
import { apiInstance } from '@src/api/axiosConfig';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Modal, Switch, message } from 'antd';
import { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';

const PHARMACY_USER_TYPE = 'PHARMACY';

function UpdateUserModal({ rowData, refetchPharmacyUsers }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pharmacyUpdateForm] = Form.useForm();

  // User update mutation
  const { mutate: userPatchMutate } = useMutation({
    mutationKey: ['patch', 'user'],
    mutationFn: (payload) =>
      apiInstance.patch(`/admin/user/${rowData.userId}`, payload),
    onSuccess: () => {
      refetchPharmacyUsers({ userType: PHARMACY_USER_TYPE });
    }
  });

  // Pharmacy update mutation
  const { mutate: pharmacyPatchMutate, isLoading: pharmacyPatchLoading } =
    useMutation({
      mutationKey: ['patch', 'pharmacy'],
      mutationFn: (payload) =>
        apiInstance.patch(
          `/admin/pharmacy/${rowData.pharmacy.pharmacyId}`,
          payload
        ),
      onSuccess: () => {
        refetchPharmacyUsers({ userType: PHARMACY_USER_TYPE });
      }
    });

  const handlePharmacyUpdate = (updates) => {
    pharmacyPatchMutate(updates, {
      onSuccess: () => {
        message.success('Eczane bilgileri başarıyla güncellendi!', 5);
      },
      onError: () => {
        message.error('Eczane bilgileri güncellenirken hata oluştu!', 5);
      }
    });
  };

  const handleUserUpdateFormOnFinish = (value) => {
    const { gln, pharmacyName, ...userUpdateValues } = value;

    userPatchMutate(
      { ...userUpdateValues, isActive: value.isActive ? 1 : 0 },
      {
        onSuccess: () => {
          // Only update pharmacy if GLN or name changed
          const pharmacyUpdates = {};
          if (rowData.pharmacy.gln !== gln) pharmacyUpdates.gln = gln;
          if (rowData.pharmacy.name !== pharmacyName)
            pharmacyUpdates.name = pharmacyName;

          if (Object.keys(pharmacyUpdates).length > 0) {
            handlePharmacyUpdate(pharmacyUpdates);
          } else {
            refetchPharmacyUsers({
              userType: 'PHARMACY'
            });
          }

          message.success('Kullanıcı güncelleme başarılı!', 5);
          closeModal();
        },
        onError: () => {
          message.error('Kullanıcı güncelleme başarısız!', 5);
        }
      }
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  return (
    <>
      <span onClick={showModal} className="edit-button" />
      <Modal
        className="modal"
        centered
        open={isModalVisible}
        closeIcon={<CloseIcon />}
        footer={null}
        title={'Kullanıcıyı Düzenle'}
        onCancel={() => closeModal()}
      >
        <div className="modal-context-container">
          <Form
            id={`userUpdateForm${rowData.userId}`}
            initialValues={{
              pharmacyName: rowData.pharmacy.name,
              phone: rowData.phone,
              email: rowData.email,
              gln: rowData.pharmacy.gln,
              isActive: rowData.isActive
            }}
            className="modal-context-form"
            form={pharmacyUpdateForm}
            onFinish={handleUserUpdateFormOnFinish}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Form.Item
                name="pharmacyName"
                rules={[{ required: true, message: 'Lütfen isminizi girin!' }]}
                style={{
                  width: 210
                }}
              >
                <Input placeholder={'Eczane İsmi'} />
              </Form.Item>

              <Form.Item name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>

            <Form.Item
              name="email"
              rules={[
                {
                  ...EMAIL_REQUIRED,
                  message: EMAIL_MESSAGE
                }
              ]}
            >
              <Input placeholder="example@domain.com" />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                { required: true, message: 'Lütfen telefonunuzu girin!' }
              ]}
            >
              <Input placeholder={'Telefon'} />
            </Form.Item>

            <Form.Item
              name="gln"
              rules={[
                {
                  required: true,
                  message: GLN_NUMBER_REQUIRED_MESSAGE
                },
                {
                  min: 13,
                  message: GLN_NUMBER_DIGIT_MESSAGE
                },
                {
                  max: 13,
                  message: GLN_NUMBER_DIGIT_MESSAGE
                },
                {
                  pattern: GLN_NUMBER_REGEX,
                  message: GLN_NUMBER_PATTERN_MESSAGE
                }
              ]}
            >
              <Input placeholder={'GLN'} />
            </Form.Item>
          </Form>
          <div className="modal-context-footer">
            <Button
              className="modal-button"
              onClick={() => pharmacyUpdateForm.submit()}
              loading={pharmacyPatchLoading}
            >
              Oluştur
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UpdateUserModal;
