import { Checkbox, Input, Modal } from 'antd';
import { useState } from 'react';

function TableRejectPopup({
  handleRequestRejectConfirm,
  isModalVisible,
  setIsModalVisible,
  requestsCount
}) {
  const [rejectedMessage, setRejectedMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(true);

  function messageOpenOnChange() {
    setMessageOpen(messageOpen ? false : true);
  }

  function handleRejectedMessageChange(value) {
    setRejectedMessage(value);
  }

  function handleModalMessage() {
    if (messageOpen) {
      const rejectedMessageData = {
        rejectedMessage: rejectedMessage,
        rejectedTitle: 'Ret Nedeni'
      };
      handleRequestRejectConfirm(rejectedMessageData);
    } else {
      handleRequestRejectConfirm(undefined);
    }
    setIsModalVisible(false);
  }

  return (
    <Modal
      title={`Seçili ${requestsCount} eczane isteği reddedilecek!`}
      open={isModalVisible}
      onOk={handleModalMessage}
      maskClosable={false}
      closable={false}
      onCancel={() => setIsModalVisible(false)}
    >
      <div>
        <Checkbox defaultChecked={true} onChange={messageOpenOnChange}>
          <h4>Ret Nedeni (Opsiyonel)</h4>
        </Checkbox>

        {messageOpen && (
          <div>
            <Input.TextArea
              onChange={(e) => handleRejectedMessageChange(e.target.value)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default TableRejectPopup;
